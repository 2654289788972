import React, { useEffect } from 'react';
import { graphql, Link  } from 'gatsby'
import * as b from "../components/bootstrap.module.css"
import RankingWidget from '../components/ranking-widget'
import BlogContent from '../components/blog-content'
import Translate from '../components/translate';
import Breadcrumbs from "../components/breadcrumbs";
import useRankings from '../hooks/useRankings';
import Thumbnail from "../components/thumbnail";
import useMedia from "../hooks/useMedia";
import useProducts from '../hooks/useProducts';
import FirstPlace from '../../static/assets/Ranking-1.svg';
import SecondPlace from '../../static/assets/Ranking-2.svg';
import ThirdPlace from '../../static/assets/Ranking-3.svg';
import Icon from "../components/icon.tsx"
import resolveUrl from '../utils/urls/resolve-url';
import Layout from "../components/layout";

import BaseHead from '../components/base-head';
import siteMetadata from "../utils/site-metadata";

export const Head = ({...props}) => (
  <BaseHead {...props}>
    {props?.data?.mysqlRanking?.term_id && <meta property="og:image" content={`${siteMetadata.domain}/social-media/term/${props.data.mysqlRanking.term_id}.png`} /> }
  </BaseHead>
)

const RankingList = ({ 
  data: { mysqlRanking, allMysqlRanking }, 
  pageContext: { name, url, breadcrumbs, product: settings },
  location: { pathname }
}) => {
  const { intro, body } = mysqlRanking;

  const isMobileResolution = useMedia(['(max-width: 992px)'], [true], false);

  const { objectLists } = useProducts({ types: 'all' })
  const { rankingLists } = useRankings({ lists: objectLists, types: url, limit: 3 })

  useEffect(() => {
    document.cookie = `firm_type=${settings.cluster}; domain=.akredo.pl; path=/`;
  }, [settings.cluster]);

  return (
    <Layout pathname={pathname}>
        <div className={b.pbMd4}>
          <section className={['ranking-intro', b.py4, b.pyMd5].join(' ')}>
            <div className={[b.container, "breadcrumbs-mobile-products", b.pb3,"scroll-inner"].join(" ")}>
              <Breadcrumbs items={breadcrumbs} />
            </div>
            <div className={b.container}>
              <div className={b.row}>
                <div className={b.colLg10}>
                  <h1 className={`big-title blackToWhite ${b.pb3} ${b.w75} ${b.ptLg5}`}>{name}</h1>
                  {intro && isMobileResolution && <BlogContent content={intro} className={[b.my3, b.mbLg5,'blackToWhite'].join(' ')} />}
                </div>
              </div>
                <div className={[b.row,b.pt4,b.alignItemsCenter].join(' ')}>
                {
                  rankingLists.map((rankingItems) => {
                    return rankingItems[1].map((ranking, i) => {
                      return (
                        <div key={i} className={[`ranking-placement-${i+1}`,b.justifyContentCenter,b.dFlex,b.alignItemsCenter].join(' ')} id={ranking.name}>
                          <a href={`#position-${i+1}`}>
                            {i === 0 ?  <FirstPlace /> : ''}
                            {i === 1 ?  <SecondPlace /> : ''}
                            {i === 2 ?  <ThirdPlace /> : ''}
                          </a>
                          <div className='product-box-img-mobile-box'><Thumbnail image={ranking.mysqlImage} alt={name} /></div>
                        </div>
                      )
                    })
                  })
                }
                </div>
            </div>
          </section>
        </div>
      <article className={[b.container, b.py3].join(' ')}>
        <RankingWidget types={url} limit={999} theme='full' settings={settings} />

        <h2 className={b.pt5}><Translate tKey="Check other rankings"/>:</h2>
        <div>
          <div className={[b.row].join(' ')}>
            {allMysqlRanking.edges.map(({ node }, i) => (
              <div key={i} className={[b.colLg2,b.colMd4,b.mt3].join(' ')}>
                <div className='ranking-other-icon'>
                  <Link to={resolveUrl(node.prefix_url, node.url)}>
                    <div className={[b.dFlex,b.justifyContentCenter,b.alignItemsCenter,b.pb2].join(' ')}>
                      <Translate tKey="account-ranking-url">{(text) => node.url.startsWith('ranking-kont-dla-dzieci') ? <Icon size={40} icon="Junior-account" className='icon-width'/> : node.url.startsWith('ranking-kont-oszczednosciowych') ? <Icon size={40} icon="Piggy-bank" className='icon-width'/> : node.url.startsWith(`${text}`) ? <Icon size={40} icon="Bank-account" className='icon-width'/> : '' }</Translate>
                      <Translate tKey="payday-loans-ranking-url">{(text) => node.url.startsWith(`${text}`) ? <Icon size={40} icon="Loans" className='icon-width'/> : '' }</Translate>
                      <Translate tKey="installment-loan-ranking-url">{(text) => node.url.startsWith(`${text}`) ? <Icon size={40} icon="Installment-loan" className='icon-width'/> : '' }</Translate>
                      <Translate tKey="cash-credit-ranking-url">{(text) => node.url.startsWith(`${text}`) ? <Icon size={40} icon="Cash-loan" className='icon-width'/> : '' }</Translate>
                      <Translate tKey="credit-card-ranking-url">{(text) => node.url.startsWith(`${text}`) ? <Icon size={40} icon="Credit-cards" className='icon-width'/> : '' }</Translate>
                      <Translate tKey="Car ranking">{(text) => node.name.includes(`${text}`) ? <Icon size={40} icon="Car-loan" className='icon-width'/> : '' }</Translate>
                      <Translate tKey="Mortgage ranking">{(text) => node.name.includes(`${text}`) ? <Icon size={40} icon="Mortgage" className='icon-width'/> : '' }</Translate>
                      <Translate tKey="Consolidation ranking">{(text) => node.name.includes(`${text}`) ? <Icon size={40} icon="Consolidation-loan" className='icon-width'/> : '' }</Translate>
                      <Translate tKey="Nonbank cards ranking">{(text) => node.name.includes(`${text}`) ? <Icon size={40} icon="Non-bank-cards" className='icon-width'/> : '' }</Translate>
                      <Translate tKey="Installment loan ranking">{(text) => node.name.includes(`${text}`) ? <Icon size={40} icon="Installment-loan" className='icon-width'/> : '' }</Translate>
                      <Translate tKey="Bank deposit rankings">{(text) => node.name.includes(`${text}`) ? <Icon size={40} icon="Investment" className='icon-width'/> : '' }</Translate>
                    </div>
                       <p className={[b.textUppercase,'tp-name',b.mb0].join(' ')}>
                        {node.name}
                        </p>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
        {body && <BlogContent content={body} className={[b.mt3, b.mtLg5].join(' ')} />}
      </article>
    </Layout>
  )
}

export default RankingList

export const query = graphql`
  query RankingList($mysqlId: Int!, $url: String!, $name: String!) {
    mysqlRanking(url: {eq: $url}, name: {eq: $name}) {
      intro
      description
      body
      type
      term_id
    }
    allMysqlRanking(filter: {url: { ne: $url }, active: {eq: 1}, hidden: { ne: 1 }, term_id: {ne: null}}) {
      edges {
        node {
          name
          url
          prefix_url
          type
        }
      }
    }
    allMysqlSeo(filter: { item_id: { eq: $mysqlId }, item_type: { eq: "ranking" }}) {
      nodes {
        ...SeoData
      }
    }
  }
`